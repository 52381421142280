import { Button, Input, Table, Tag, Transfer } from 'antd'
import React, { useEffect, useState } from 'react'
import difference from 'lodash/difference'
import { httpGetAdmin } from '../../services/http'

const TableTransfer = ({ leftColumns, rightColumns, ...restProps }) => (
    <Transfer {...restProps} showSelectAll={false}>
        {({
            direction,
            filteredItems,
            onItemSelectAll,
            onItemSelect,
            selectedKeys: listSelectedKeys,
            disabled: listDisabled,
        }) => {
            const columns = direction === 'left' ? leftColumns : rightColumns

            const rowSelection = {
                getCheckboxProps: (item) => ({ disabled: listDisabled || item.disabled }),
                onSelectAll(selected, selectedRows) {
                    const treeSelectedKeys = selectedRows
                        .filter((item) => !item.disabled)
                        .map(({ key }) => key)
                    const diffKeys = selected
                        ? difference(treeSelectedKeys, listSelectedKeys)
                        : difference(listSelectedKeys, treeSelectedKeys)
                    onItemSelectAll(diffKeys, selected)
                },
                onSelect({ key }, selected) {
                    onItemSelect(key, selected)
                },
                selectedRowKeys: listSelectedKeys,
            }

            return (
                <Table
                    rowSelection={rowSelection}
                    columns={columns}
                    dataSource={filteredItems as any}
                    size="small"
                    style={{ pointerEvents: listDisabled ? 'none' : null }}
                    onRow={(inputData) => {
                        const { key, disabled: itemDisabled } = inputData as any
                        return {
                            onClick: () => {
                                if (itemDisabled || listDisabled) return
                                onItemSelect(key, !listSelectedKeys.includes(key))
                            },
                        }
                    }}
                />
            )
        }}
    </Transfer>
)

const leftTableColumns = [
    {
        dataIndex: 'email',
        title: 'Email',
    },
    {
        dataIndex: 'has_paid',
        title: 'has_paid',
        render: (has_paid) => (
            <Tag color={has_paid ? 'success' : 'error'}>{has_paid ? 'yes' : 'no'}</Tag>
        ),
    },
]
const rightTableColumns = [
    {
        dataIndex: 'email',
        title: 'Email',
    },
]

export default function ComposeMailRecipientTransfer(fieldRenderProps) {
    const [users, setUsers] = useState([])
    const [addUser, setAddUser] = useState('')

    function addUserToSource () {
        const newUser = {email: addUser, has_paid: true, id: addUser, key: addUser}
        if (fieldRenderProps.value) {
            fieldRenderProps.onChange([...fieldRenderProps.value, addUser])
        }
        setUsers([...users, newUser])
    }

    useEffect(() => {
        type usersData = {
            users: any[]
        }
        httpGetAdmin<usersData>('/ll-admin/ll-users').then((r) => {
            setUsers(r.data.users)
        })
    }, [])

    return (
        <>
            <Input
                value={addUser}
                onChange={(e) => setAddUser(e.target.value)}
                placeholder="name@example.com"
                style={{ width: '40%' }}
                onPressEnter={addUserToSource}
                addonAfter={<Button onClick={addUserToSource}>Add</Button>}
            ></Input>
            <TableTransfer
                dataSource={users}
                targetKeys={fieldRenderProps.value}
                showSearch={true}
                onChange={fieldRenderProps.onChange}
                filterOption={(inputValue, item) =>
                    item.email.indexOf(inputValue) !== -1
                }
                leftColumns={leftTableColumns}
                rightColumns={rightTableColumns}
            />
        </>
    )
}
