import { Button, Form, Input, message, Modal, Popconfirm, Space } from 'antd'
import React, { useState } from 'react'
import AdminLayout from './AdminLayout'
import { httpPostAdmin, httpPutAdmin } from '../../services/http'
import ComposeMailRecipientTransfer from './ComposeMailRecipientTransfer'

const { TextArea } = Input

export default function ComposeMail() {
    const [loading, setLoading] = useState(false)
    const [previewHtml, setPreviewHtml] = useState('<div/>')
    const [form] = Form.useForm()

    const error = (message: string) => {
        Modal.error({
            title: 'Signup failed',
            content: message,
        })
    }

    const manualFormFinish = () => {
        form.validateFields()
            .then((values) => {
                onFinish(values)
            })
            .catch((error) => {})
    }

    const manualFormPreview = () => {
        form.validateFields()
            .then((values) => {
                httpPutAdmin<{html: any}>('/ll-admin/compose-mail', {
                    title: values.title,
                    content: values.content,
                    recipients: values.recipients,
                    message_subject: values.message_subject,
                }).then( r => {
                    setPreviewHtml(r.data.html)
                })
            })
            .catch((error) => {})
    }

    const onFinish = async (values: any) => {
        setLoading(true)
        try {
            const res = await httpPostAdmin<{num_successes: number}>('/ll-admin/compose-mail', {
                title: values.title,
                content: values.content,
                recipients: values.recipients,
                message_subject: values.message_subject,
            })
            message.success(`Mail was sent to ${res.data.num_successes} of ${values.recipients.length}!`)
        } catch (err) {
            if (err.status === 400) {
                error(err.data?.detail)
            } else {
                error(err.message)
            }
        }
        setLoading(false)
    }

    return (
        <AdminLayout>
            <div style={{ margin: 30 }}>
                <Form onFinish={onFinish} form={form}>
                    <Form.Item
                        name="recipients"
                        label="recipients"
                        rules={[{ required: true, message: 'Please enter recipients' }]}
                        className="signin-input"
                    >
                        <ComposeMailRecipientTransfer />
                    </Form.Item>
                    <Form.Item
                        name="message_subject"
                        label="Mail subject"
                        rules={[{ required: true, message: 'Please enter subject' }]}
                        className="signin-input"
                    >
                        <Input placeholder="Subject" />
                    </Form.Item>
                    <Form.Item
                        name="title"
                        label="title"
                        rules={[{ required: true, message: 'Please enter title' }]}
                        className="signin-input"
                    >
                        <Input placeholder="Title" />
                    </Form.Item>
                    <Form.Item
                        name="content"
                        label="content"
                        rules={[{ required: true, message: 'Please enter content' }]}
                        className="signin-input"
                    >
                        <TextArea rows={4} />
                    </Form.Item>
                    <Form.Item>
                        <Space>
                            <Popconfirm
                                title="Are you sure?"
                                // visible={popConfirmVisible}
                                onConfirm={manualFormFinish}
                                // okButtonProps={{ htmlType: 'submit' }}
                                // onCancel={handleCancelDelete}
                            >
                                <Button
                                    className="landing-button"
                                    shape="round"
                                    htmlType="submit"
                                    type="primary"
                                    loading={loading}
                                >
                                    Send
                                </Button>
                            </Popconfirm>
                            <Button
                                className="landing-button"
                                shape="round"
                                loading={loading}
                                onClick={manualFormPreview}
                            >
                                Preview
                            </Button>
                        </Space>
                    </Form.Item>
                </Form>
                <div dangerouslySetInnerHTML={{__html: previewHtml}} />
            </div>
        </AdminLayout>
    )
}
